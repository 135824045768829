import axiosInstance from 'config/axiosConfig';
import {
  POINT_CONVERSION_VALIDATE_OPERATION,
  POINT_CONVERSIONS_API,
  POINT_CONVERSIONS_API_UPDATE_STATUS
} from 'constants/api';
import { DEFAULT_POINT_CONVERSIONS_SIZE } from 'constants/api/pointConversions';

class PointConversionsApi {
  async getPointConversions() {
    const { data } = await axiosInstance().get(POINT_CONVERSIONS_API, {
      params: {
        ...DEFAULT_POINT_CONVERSIONS_SIZE
      }
    });

    return data;
  }

  async validatePointConversion(pointConversion) {
    await axiosInstance().put(POINT_CONVERSIONS_API_UPDATE_STATUS, {
      data: [
        {
          id: pointConversion.id,
          operation: POINT_CONVERSION_VALIDATE_OPERATION
        }
      ]
    });
  }
}

export default PointConversionsApi;
