import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { UserContext } from 'components/App';
import Loading from 'components/atoms/ui/Loading';
import { LOADER_TYPE } from 'constants/general';
import WallDashBoardLayout from 'components/organisms/wall/WallDashBoardLayout';
import ProtectedRoute from 'components/organisms/layouts/ProtectedRoute';
import WallRouter from 'components/router/WallRouter';
import { ALL_ROUTES, METRICS_ROUTE, PAGE_NOT_FOUND, WALL_PROGRAM_ROUTE } from 'constants/routes';
import { useStoredProgramData } from 'hooks/programs/useStoredProgramData';
import { useWallSelection } from 'hooks/wall/useWallSelection';
import { IStore } from 'interfaces/store/IStore';
import { getUserAuthorizations, hasAtLeastSuperRole, isUserBeneficiary } from 'services/security/accessServices';
import { setHandleRedirectOnLogin } from 'store/actions/generalActions';
import { forceActiveProgram } from 'store/actions/wallActions';

/**
 * Router component used to set layouts and include the correct routes for wall
 * @constructor
 */
const WallPreRouter = () => {
  const routerMatch = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { componentLoading, userData } = useContext(UserContext);
  const { platforms, loadingPlatforms } = useWallSelection();
  const { programDetails } = useStoredProgramData();
  const { redirectOnLogin } = useSelector((store: IStore) => store.generalReducer);

  // if the user is redirected to wall from a program onboarding login/mail and the platform or program he
  // tries to access is not in the selector, we redirect him to the programs page for that platform/program,
  // to let him join that program
  const checkAndRedirectForNotJoinedPrograms = programDetails => {
    const checkedPlatform = platforms.find(platform => platform.id == programDetails.platformId);
    const checkedPlatformProgram =
      checkedPlatform && checkedPlatform.programs.find(program => program.id == programDetails.programId);
    if (!checkedPlatform || !checkedPlatformProgram) {
      const redirectPath = `${WALL_PROGRAM_ROUTE}/${programDetails.programType}/${programDetails.programId}/${programDetails.customUrl}`;
      history.push(redirectPath);
    }
  };

  useEffect(() => {
    const { highestRole } = userData;

    if (!loadingPlatforms && programDetails && isUserBeneficiary(highestRole)) {
      checkAndRedirectForNotJoinedPrograms(programDetails);
    }

    if (!(redirectOnLogin && hasAtLeastSuperRole(highestRole))) {
      return;
    }

    const { isSuperAdmin, isSuperManager, isHyperAdmin } = getUserAuthorizations(highestRole);
    const ensureSubPlatformPreselected = isSuperAdmin || isSuperManager;
    // loadingPlatforms is a flag which we can use to verify if platform retrieval is finished
    if (ensureSubPlatformPreselected && loadingPlatforms) {
      return;
    }

    dispatch(setHandleRedirectOnLogin(false));
    if (ensureSubPlatformPreselected) {
      // since super/hyper platforms are not saved in the platforms store, the first element for a super/hyper role
      // will be the subplatform
      const subPlatform = platforms[0];
      if (subPlatform) {
        dispatch(forceActiveProgram({ forcedPlatformId: subPlatform.id, unlockSelection: true }));
        return;
      }
    }

    history.push(isHyperAdmin ? METRICS_ROUTE : WALL_PROGRAM_ROUTE);
  }, [userData, redirectOnLogin, history, platforms, loadingPlatforms]);

  if (componentLoading || loadingPlatforms) {
    return <Loading type={LOADER_TYPE.PAGE} />;
  }

  return (
    <WallDashBoardLayout>
      <Switch>
        <ProtectedRoute path={routerMatch.path} component={WallRouter} />
        <Redirect from={ALL_ROUTES} to={PAGE_NOT_FOUND} />
      </Switch>
    </WallDashBoardLayout>
  );
};

export default WallPreRouter;
