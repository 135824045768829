import {
  DEFAULT_MAX_SIZE_REQUEST,
  DEFAULT_POINT_CONVERSION_STATUS_DONE,
  DEFAULT_POINT_CONVERSION_STATUS_PENDING
} from '../general';

export const DEFAULT_POINT_CONVERSIONS_SIZE = Object.freeze({ offset: 0, size: DEFAULT_MAX_SIZE_REQUEST });

export enum POINT_CONVERSION_STATUS {
  PENDING = DEFAULT_POINT_CONVERSION_STATUS_PENDING,
  DONE = DEFAULT_POINT_CONVERSION_STATUS_DONE
}
