import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import EmailList from 'components/molecules/wall/userInvite/EmailList';
import DynamicFormattedError from 'components/atoms/ui/DynamicFormattedError';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { useEmailListData } from 'hooks/wall/useEmailListData';
import { emptyFn, hasValidEmailFormat } from 'utils/general';
import { HTML_TAGS, KEYCODES } from 'constants/general';
import { INVITE_EMAIL_LIMIT } from 'constants/wall/users';
import { EMAIL } from 'constants/validation';

import style from 'assets/style/common/Input.module.scss';
import settingsStyle from 'sass-boilerplate/stylesheets/components/wall/PersonalnformationSettings.module.scss';

/**
 * Molecule component used to render email invitation section
 * @param translationKey
 * @param id
 * @param userEmail
 * @param userData
 * @constructor
 */
const InviteByEmail = ({ translationKey, id, userEmail, userData }) => {
  const { container, defaultInputStyle } = style;

  const { addChip, addChipDisabled } = settingsStyle;
  const { emails, addEmail, setEmail, removeEmail, email, error } = useEmailListData(userEmail, userData);
  const enableAdd = hasValidEmailFormat(email);
  const handleSubmit = e => {
    if (hasValidEmailFormat(e.target.value) && e.keyCode === KEYCODES.ENTER) {
      addEmail();
    }
  };

  return (
    <>
      <EmailList {...{ emails, removeEmail }} />
      <div className={container}>
        <input
          className={defaultInputStyle}
          onKeyUp={handleSubmit}
          onChange={e => setEmail(e.target.value)}
          value={email}
          disabled={emails.length > INVITE_EMAIL_LIMIT}
          type={EMAIL}
          id={id}
          name="inviteUser"
        />
        <span
          className={`${addChip} ${!enableAdd ? addChipDisabled : ''}`}
          onClick={() => (enableAdd ? addEmail() : emptyFn)}
        >
          <FontAwesomeIcon icon={faPlus} />
          <DynamicFormattedMessage tag={HTML_TAGS.SPAN} id={'label.add'} />
        </span>
        <DynamicFormattedMessage tag={HTML_TAGS.LABEL} className="inputLabel" id={`${translationKey}${id}.label`} />
        <DynamicFormattedError hasError={error} id={`wall.settings.account.${error}`} />
      </div>
    </>
  );
};

export default InviteByEmail;
