import React from 'react';

import PointConversionsHeaderElement from './PointConversionsHeaderElement';
import { POINT_CONVERSION_HEADERS } from 'constants/wall/users';
import { useWindowSize } from 'hooks/others/useWindowSize';
import { WINDOW_SIZES } from 'constants/general';

/**
 * Molecule component used to render Point Conversions Header
 *
 * @param props
 * @param props.sortState current sorting state
 * @param props.onSort callback to execute on sorting change
 * @constructor
 */
const PointConversionsHeader = ({ sortState, onSort, isLoading = false, headers = POINT_CONVERSION_HEADERS }) => {
  const { windowSize } = useWindowSize();
  const isSmallWindow = windowSize.width < WINDOW_SIZES.DESKTOP_SMALL;

  return (
    <thead>
      <tr>
        {headers.map(header => {
          const skipSorting = isSmallWindow || (header as any).isNotSortable;
          return (
            <td key={header.id}>
              <PointConversionsHeaderElement {...header} {...{ skipSorting, sortState, onSort, isLoading }} />
            </td>
          );
        })}
      </tr>
    </thead>
  );
};

export default PointConversionsHeader;
