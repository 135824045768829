import React from 'react';

import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import GeneralBlock from 'components/molecules/block/GeneralBlock';
import { HTML_TAGS, IMAGES_ALT } from 'constants/general';
import { useCheckAndRedirectPaypal } from 'hooks/user/useCheckAndRedirectPaypal';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';

import card from 'assets/images/wall/card.png';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import widgetStyle from 'sass-boilerplate/stylesheets/components/wall/widgets/Widget.module.scss';

/**
 * Organism component used to render payment widget
 *
 * @constructor
 */
const PaymentWidget = ({ isAdmin }) => {
  const {
    withBoldFont,
    withGrayAccentColor,
    textCenter,
    displayInlineBlock,
    pointer,
    imgFluid,
    maxWidth20,
    mb2,
    widthFull
  } = coreStyle;
  const { widgetTitle, widgetTextBody, widgetPayment } = widgetStyle;
  const { colorMainButtons, colorWidgetTitle } = useSelectedProgramDesign();
  const { onRewardsRedirect } = useCheckAndRedirectPaypal();

  return (
    <GeneralBlock className={`${withGrayAccentColor} ${widgetPayment} ${textCenter} ${wallStyle.hideBlockMobile}`}>
      <>
        <DynamicFormattedMessage
          className={`${mb2} ${withBoldFont} ${widgetTitle}`}
          id="wall.rewards.title"
          tag={HTML_TAGS.P}
          style={{ color: colorWidgetTitle }}
        />
        <img
          className={`${mb2} ${displayInlineBlock} ${textCenter} ${imgFluid} ${maxWidth20}`}
          src={card}
          alt={IMAGES_ALT.CARD}
        />
        <DynamicFormattedMessage
          className={`${pointer} ${widthFull} ${widgetTextBody}`}
          onClick={onRewardsRedirect}
          id={isAdmin ? 'wall.rewards.cta' : 'wall.rewards.cta.beneficiary'}
          style={{ color: colorMainButtons }}
          tag={HTML_TAGS.SPAN}
        />
      </>
    </GeneralBlock>
  );
};

export default PaymentWidget;
