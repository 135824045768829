import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { LAUNCH_PRODUCTS_FIRST, LAUNCH_RESULTS_FIRST, LAUNCH_USER_FIRST } from 'constants/routes';
import { RESULTS, PRODUCTS } from 'constants/wall/launch';
import { IStore } from '../../../interfaces/store/IStore';
import { CLOSED } from '../../../constants/general';

/**
 * Hook used to handle all back cases
 *
 * @param personaliseProducts
 * @param stepSet
 * @param params
 */
export const useBackHandles = (stepSet, { stepIndex, step }) => {
  const history = useHistory();
  const launchStoreData = useSelector((store: IStore) => store.launchReducer);
  const { confidentiality, personaliseProducts, resultChannel } = launchStoreData;
  const isClosedProgram = confidentiality === CLOSED;

  const handleBackStep = () => {
    if (!personaliseProducts && step === RESULTS && parseInt(stepIndex) === 1) {
      return history.push(LAUNCH_PRODUCTS_FIRST);
    }
    if (resultChannel && !resultChannel.declarationForm && step === RESULTS && parseInt(stepIndex) === 3) {
      return history.push(LAUNCH_RESULTS_FIRST);
    }
    if (!isClosedProgram && step === PRODUCTS && parseInt(stepIndex) === 1) {
      return history.push(LAUNCH_USER_FIRST);
    }

    stepSet.setPrevStep();
  };

  return { handleBackStep };
};
