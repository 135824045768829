import React from 'react';
import { Link } from 'react-router-dom';

import GeneralBlock from 'components/molecules/block/GeneralBlock';
import useUserNumber from 'hooks/wall/useUserNumber';
import Loading from 'components/atoms/ui/Loading';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS, LOADER_TYPE } from 'constants/general';
import { USERS_ROUTE } from 'constants/routes';
import { useWallSelection } from 'hooks/wall/useWallSelection';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';
import { useWallRoute } from 'hooks/wall/useWallRoute';
import { numberWithSpaces } from 'utils/general';

import style from 'sass-boilerplate/stylesheets/components/wall/WallUserBlock.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';

/**
 * Molecule component used to render user block
 * @constructor
 */
const UserNumberWidget = () => {
  const { userBlock, userBlockTitle, userBlockLink, userBlockCount, userBlockNumbers } = style;
  const { currentProgramUsers, isLoading } = useUserNumber();
  const { colorMainButtons, colorTitle, colorWidgetTitle } = useSelectedProgramDesign();
  const { isWallRoute, isCommunicationRoute } = useWallRoute();
  const { selectedProgramIndex, selectedProgramId } = useWallSelection();
  let blockTitle = 'wall.user.block.user';
  if (selectedProgramIndex === 0 && !selectedProgramId) {
    blockTitle = 'wall.user.block.userUnique';
  }

  return (
    <GeneralBlock className={`${userBlock} ${userBlockNumbers} ${wallStyle.hideBlockMobile}`}>
      <DynamicFormattedMessage
        style={{ color: colorWidgetTitle }}
        className={userBlockTitle}
        id={blockTitle}
        tag={HTML_TAGS.SPAN}
      />
      {(isLoading && (
        <div className={userBlockCount}>
          <Loading className={coreStyle.withSecondaryColor} type={LOADER_TYPE.DROPZONE} />
        </div>
      )) || (
        <p className={userBlockCount} style={{ color: isWallRoute || isCommunicationRoute ? colorTitle : '' }}>
          {numberWithSpaces(currentProgramUsers)}
        </p>
      )}
      <DynamicFormattedMessage
        tag={Link}
        to={USERS_ROUTE}
        className={userBlockLink}
        id="wall.user.see.all"
        style={{ color: isWallRoute || isCommunicationRoute ? colorMainButtons : '' }}
      />
    </GeneralBlock>
  );
};

export default UserNumberWidget;
