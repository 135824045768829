import { USER_DECLARATIONS_SORTING, POINT_CONVERSIONS_SORTING } from 'constants/api/declarations';

export const FILE = 'file';
export const EMAIL = 'email';
export const ALL_USERS = 'allusers';
export const INVITE_EMAIL_LIMIT = 10;

export const USER_DECLARATION_HEADERS = Object.freeze([
  {
    id: 'source',
    sortBy: USER_DECLARATIONS_SORTING.SOURCE
  },
  {
    id: 'id',
    sortBy: USER_DECLARATIONS_SORTING.ID
  },
  {
    id: 'programName',
    sortBy: USER_DECLARATIONS_SORTING.PROGRAM_NAME
  },
  {
    id: 'programType',
    sortBy: USER_DECLARATIONS_SORTING.PROGRAM_TYPE
  },
  {
    id: 'occurredOn',
    sortBy: USER_DECLARATIONS_SORTING.OCCURRED_ON
  },
  {
    id: 'declarant',
    sortBy: USER_DECLARATIONS_SORTING.USER
  },
  {
    id: 'customerCompany',
    sortBy: USER_DECLARATIONS_SORTING.COMPANY_NAME
  },
  {
    id: 'target',
    sortBy: USER_DECLARATIONS_SORTING.FIRST_NAME
  },
  {
    id: 'product',
    sortBy: USER_DECLARATIONS_SORTING.PRODUCT_NAME
  },
  {
    id: 'quantity',
    sortBy: USER_DECLARATIONS_SORTING.QUANTITY
  },
  {
    id: 'amount',
    sortBy: USER_DECLARATIONS_SORTING.AMOUNT
  },
  {
    id: 'status',
    sortBy: USER_DECLARATIONS_SORTING.STATUS
  },
  {
    id: 'by',
    sortBy: USER_DECLARATIONS_SORTING.VALIDATED_BY
  }
]);

export const POINT_CONVERSION_HEADERS = Object.freeze([
  {
    id: 'company',
    sortBy: POINT_CONVERSIONS_SORTING.COMPANY,
    isNotSortable: true
  },
  {
    id: 'superplatformName',
    sortBy: POINT_CONVERSIONS_SORTING.SUPERPLATFORM_NAME,
    isNotSortable: true
  },
  {
    id: 'platformName',
    sortBy: POINT_CONVERSIONS_SORTING.PLATFORM_NAME,
    isNotSortable: true
  },
  {
    id: 'programName',
    sortBy: POINT_CONVERSIONS_SORTING.PROGRAM,
    isNotSortable: true
  },
  {
    id: 'firstName',
    sortBy: POINT_CONVERSIONS_SORTING.FIRST_NAME,
    isNotSortable: true
  },
  {
    id: 'lastName',
    sortBy: POINT_CONVERSIONS_SORTING.LAST_NAME,
    isNotSortable: true
  },
  {
    id: 'paypalLink',
    sortBy: POINT_CONVERSIONS_SORTING.PAYPAL_LINK,
    isNotSortable: true
  },
  {
    id: 'points',
    sortBy: POINT_CONVERSIONS_SORTING.POINTS_TO_CONVERT,
    isNotSortable: true
  },
  {
    id: 'status',
    sortBy: POINT_CONVERSIONS_SORTING.STATUS,
    isNotSortable: true
  }
]);

export const BENEFICIARY_DECLARATION_HEADERS = [
  {
    id: 'programName',
    sortBy: USER_DECLARATIONS_SORTING.PROGRAM_NAME
  },
  {
    id: 'programType',
    sortBy: USER_DECLARATIONS_SORTING.PROGRAM_TYPE
  },
  {
    id: 'occurredOn',
    sortBy: USER_DECLARATIONS_SORTING.OCCURRED_ON
  },
  {
    id: 'product',
    sortBy: USER_DECLARATIONS_SORTING.PRODUCT_NAME,
    isNotSortable: true
  },
  {
    id: 'quantity',
    sortBy: USER_DECLARATIONS_SORTING.QUANTITY
  },
  {
    id: 'amount',
    sortBy: USER_DECLARATIONS_SORTING.AMOUNT
  },
  {
    id: 'declarationForm',
    sortBy: USER_DECLARATIONS_SORTING.NONE,
    isNotSortable: true
  },
  {
    id: 'status',
    sortBy: USER_DECLARATIONS_SORTING.STATUS
  }
];

export const FINISHED = 'finished';
export const ONGOING = 'ongoing';
export const PENDING = 'pending';

export const USER_DATA_EXPORT_FILE_PARAM = 2;
export const USER_DATA_EXPORT_FILE_NAME = 'tooodooo_data-export.xls';
