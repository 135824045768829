import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import envConfig from 'config/envConfig';
import { HTML_TAGS } from 'constants/general';
import { openLegal } from 'utils/general';

import style from 'assets/style/common/Labels.module.scss';

/**
 * Molecule component thar renders general conditions text + url
 *
 * @constructor
 */
const GeneralConditions = () => {
  const { block, center, bold } = style;

  return (
    <div className={`${block} ${center}`}>
      <FormattedMessage id="personalInformation.info.general.title1" />
      <DynamicFormattedMessage
        tag={HTML_TAGS.ANCHOR}
        onClick={event => openLegal(event)}
        id="personalInformation.info.general.cta"
        href={envConfig.onboarding.legalUrl}
        className={`${bold}`}
      />
      <FormattedMessage id="personalInformation.info.general.title2" />
    </div>
  );
};

export default GeneralConditions;
