import { useDispatch, useSelector } from 'react-redux';

import { IStore } from 'interfaces/store/IStore';
import { setFilteredMeasurementType } from 'store/actions/launchActions';
import { getAcceptedMeasurementTypeArray, getUppercaseMeasurementTypeKeys } from 'services/CubeServices';
import { useUpdateEffect } from 'hooks/general/useUpdateEffect';

/**
 * Hook used to handle allocation filter
 *
 * @param index
 */
export const useCubeMeasurementTypeFilter = index => {
  const dispatch = useDispatch();
  const { cube } = useSelector((store: IStore) => store.launchReducer);
  const currentMeasurementTypes = cube.goals[index].measurementTypesValid;

  useUpdateEffect(() => {
    if (cube.acceptedCorrelatedGoals.length) {
      const currentAcceptedMeasurementTypes = getAcceptedMeasurementTypeArray(cube);
      const commonMeasurementTypes = getUppercaseMeasurementTypeKeys(currentAcceptedMeasurementTypes);
      setFilteredMeasurementType(index, commonMeasurementTypes, dispatch, cube);
    }
  }, [cube.acceptedCorrelatedGoals]);

  return { currentMeasurementTypes };
};
