import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import PersonalInformationFormAdditional from 'components/molecules/forms/PersonalInformationFormAdditional';
import GenericFormBuilder from 'components/organisms/form-wrappers/GenericFormBuilder';
import {
  PERSONAL_INFORMATION_ADMIN_FIELDS,
  PERSONAL_INFORMATION_FIELDS
} from 'constants/formDefinitions/formDeclarations';
import { submitPersonalInformation } from 'store/actions/formActions';
import { AvatarContext } from 'components/pages/PersonalInformationPage';
import { USER_DETAILS_COOKIE } from 'constants/general';
import { DISABLED, FLOATING, FORM_FIELDS } from 'constants/forms';
import {
  getUserAuthorizations,
  isAtLeastSuperAdmin,
  isAtLeastSuperCommunityManager
} from 'services/security/accessServices';
import { useUserData } from 'hooks/user/useUserData';

import styles from 'assets/style/components/PersonalInformation/PersonalInformation.module.scss';

/**
 * Template component used to display personal information form
 *
 * @constructor
 */
const PersonalInformationFormWrapper = () => {
  const history = useHistory();
  const [formLoading, setFormLoading] = useState(false);
  const { avatarData } = useContext(AvatarContext);
  const { formatMessage } = useIntl();
  const userDetails: any = JSON.parse(Cookies.get(USER_DETAILS_COOKIE));
  const { userData } = useUserData();
  const formDeclaration = userDetails.invitedToPlatform
    ? PERSONAL_INFORMATION_ADMIN_FIELDS
    : PERSONAL_INFORMATION_FIELDS;
  let isMinimumSuperAdmin = null;

  if (userDetails.invitedToPlatform && userData.invitationsRoles && userData.invitationsRoles.length > 0) {
    const userPlatform = userData.invitationsRoles.find(role => role.platform === userDetails.invitedToPlatform);
    const userRole = getUserAuthorizations(userPlatform.role);
    isMinimumSuperAdmin = isAtLeastSuperAdmin(userRole) || isAtLeastSuperCommunityManager(userRole);

    if (isMinimumSuperAdmin) {
      const roleField = formDeclaration.find(field => field.label === FORM_FIELDS.COMPANY_ROLE);
      roleField.initialValue = formatMessage({ id: 'wall.users.role.' + userPlatform.role });
      roleField.style = { ...DISABLED, ...FLOATING };
    }
  }

  return (
    <div className={styles.wrapper}>
      <GenericFormBuilder
        formAction={(values, props) =>
          submitPersonalInformation(avatarData, values, props, setFormLoading, formatMessage)
        }
        formDeclaration={[...formDeclaration]}
        formSlot={form => (
          <PersonalInformationFormAdditional {...{ form, history, formLoading, isMinimumSuperAdmin }} />
        )}
      />
    </div>
  );
};

export default PersonalInformationFormWrapper;
