import React from 'react';
import { useDispatch } from 'react-redux';

import { setModalState } from 'store/actions/modalActions';
import { IMAGE_UPLOAD_MODAL } from 'constants/modal';
import { IMAGES_ALT } from 'constants/general';

/**
 * Atom component used to render contents selection preview
 *
 * @param croppedAvatar
 * @param className
 * @constructor
 */
const ContentsSelectionPreview = ({ croppedAvatar, className = '' }) => {
  const dispatch = useDispatch();

  return (
    <div className={className}>
      <img
        onClick={() => dispatch(setModalState(true, IMAGE_UPLOAD_MODAL))}
        src={croppedAvatar}
        alt={IMAGES_ALT.COVER_IMAGE}
      />
    </div>
  );
};

export default ContentsSelectionPreview;
