import axiosInstance from 'config/axiosConfig';
import envConfig from 'config/envConfig';
import {
  API_V1,
  CONTENT_TYPE,
  CONTENT_DISPOSITION,
  MAX_TIMEOUT_PERIOD,
  MULTIPART_FORM_DATA,
  BLOB,
  GET,
  POST,
  UPLOAD_FILES_ENDPOINT
} from 'constants/api';
import { IFileUpload } from 'interfaces/api/IFileUpload';
import { IFileDownload } from 'interfaces/api/IFileDownload';
import { trimUrl } from 'utils/api';

class FilesApi {
  async downloadFile(endpointUrl: string, queryParams?: any, nameForFile: any = 'file'): Promise<IFileDownload> {
    const { data, headers } = await axiosInstance()({
      method: GET,
      url: endpointUrl,
      params: queryParams,
      responseType: BLOB
    });

    const filename = headers[CONTENT_DISPOSITION] ? headers[CONTENT_DISPOSITION].split('filename=')[1] : nameForFile;
    const contentType = headers[CONTENT_TYPE.toLowerCase()];

    return {
      file: data,
      filename,
      contentType
    };
  }

  uploadFiles(files: IFileUpload[]) {
    const url = `${trimUrl(envConfig.backendUrl, API_V1)}${UPLOAD_FILES_ENDPOINT}`;

    return this.uploadFileToUrl(url, { data: files });
  }

  uploadFileToUrl(url: string, fileData: any) {
    return axiosInstance()({
      method: POST,
      url,
      data: this.buildFormData(fileData),
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      timeout: MAX_TIMEOUT_PERIOD
    });
  }

  private buildFormData(data: any, formData: FormData = new FormData(), namespace?: string): FormData {
    for (const property in data) {
      let formKey = property;

      if (data[property] !== undefined && data[property] !== null) {
        if (namespace) {
          formKey = `${namespace}[${property}]`;
        }

        if (typeof data[property] === 'object' && !(data[property] instanceof File)) {
          this.buildFormData(data[property], formData, formKey);
        } else {
          formData.append(formKey, data[property]);
        }
      }
    }

    return formData;
  }
}

export default FilesApi;
