import { useDispatch } from 'react-redux';
import { useMultiStep } from '../useMultiStep';
import { useAvatarPictureConfigurations } from 'hooks/useAvatarPictureConfigurations';

/**
 * Hook used to handle contents logic
 */
export const useContentPageData = () => {
  const dispatch = useDispatch();
  const {
    stepSet: { setNextStep }
  } = useMultiStep();
  const contentCoverConfig = useAvatarPictureConfigurations();

  return { setNextStep, dispatch, contentCoverConfig };
};
