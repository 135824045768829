import React from 'react';
import LoginFormWrapper from 'components/organisms/form-wrappers/LoginFormWrapper';
import ButtonFormatted from '../atoms/ui/ButtonFormatted';
import { LOGIN } from 'constants/routes';
import { BUTTON_MAIN_TYPE } from 'constants/ui';
import landingImage from 'assets/images/landingBg.jpg';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import componentStyle from 'sass-boilerplate/stylesheets/components/landing/UserActions.module.scss';
import { CLOUD_REWARDS_URL, CLOUD_REWARDS_URL_EU } from '../../constants/general';

/**
 * Page component used to render login page
 *
 * @constructor
 */
const LoginPage = () => {
  const { userActionPage, formWrapper, backToLanding } = componentStyle;
  const { contentCentered } = coreStyle;
  const backButtonTarget = process.env.REACT_APP_ZONE === 'US' ? CLOUD_REWARDS_URL : CLOUD_REWARDS_URL_EU;

  return (
    <>
      <section
        className={`${userActionPage} ${contentCentered}`}
        style={{
          backgroundImage: `linear-gradient(133deg, rgba(54,185,146,.3) 0%, rgba(54,185,146,.3) 35%, rgba(112,198,132,.3) 73%, rgba(170,211,117,.3) 100%),  url(${landingImage})`
        }}
        id={LOGIN}
      >
        <ButtonFormatted
          type={BUTTON_MAIN_TYPE.PRIMARY}
          onClick={() => window.location.replace(backButtonTarget)}
          buttonText="modal.success.button.reset"
          className={backToLanding}
        />
        <div className={formWrapper}>
          <LoginFormWrapper isOnboardingFlow={false} />
        </div>
      </section>
    </>
  );
};

export default LoginPage;
