import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ContentsPageInfo from './ContentPageInfo';
import ContentsCoverPreview from 'components/molecules/contents/ContentsCoverPreview';
import { MAX_WYSIWIG_LENGTH } from 'constants/general';
import { ContentsCoverContext } from 'components/pages/ContentsPage';

import wysiwigStyle from 'sass-boilerplate/stylesheets/components/launch/Wysiwig.module.scss';
import gridStyle from 'assets/style/common/Grid.module.scss';

/**
 * Template component used to render Contents Editor page
 *
 * @param editorState
 * @param handleEditorChange
 */
const ContentsPageEditor = ({ editorState, handleEditorChange, wysiwigLength, form }) => {
  const { wysiwigToolbar, wysiwigWrapper, wysiwigEditor } = wysiwigStyle;

  return (
    <div className={gridStyle.col7}>
      <ContentsCoverPreview context={ContentsCoverContext} {...{ form }} />
      <ContentsPageInfo />
      <Editor
        editorState={editorState}
        toolbarClassName={wysiwigToolbar}
        wrapperClassName={wysiwigWrapper}
        editorClassName={wysiwigEditor}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'image',
            'history'
          ]
        }}
        handleBeforeInput={() => {
          if (wysiwigLength >= MAX_WYSIWIG_LENGTH) {
            return 'handled';
          }
        }}
        handlePastedText={val => val.length + wysiwigLength >= MAX_WYSIWIG_LENGTH}
      />
    </div>
  );
};

export default ContentsPageEditor;
