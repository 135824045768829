import React from 'react';

import GenericFormBuilder from 'components/organisms/form-wrappers/GenericFormBuilder';
import ContentFormAdditional from 'components/molecules/forms/contents/ContentFormAdditional';
import ContentsBackgroundCover from 'components/organisms/launch/contents/ContentsBackgroundCover';
import { contentsPageParametersAction } from 'store/actions/formActions';
import { useContentData } from 'hooks/contents/useContentData';
import { useContentsImages } from 'hooks/contents/useContentsImages';

import style from 'assets/style/components/wall/GeneralWallStructure.module.scss';
import gridStyle from 'assets/style/common/Grid.module.scss';
import contentsStyle from 'assets/style/components/launch/Contents.module.scss';

/**
 * Organism component used to render Contents Wrapper page
 *
 * @constructor
 */
const ContentsPageWrapperForm = () => {
  const { accentLabels, containerSmall, alignCenterOnDesktop } = style;
  const { col12, col4, containerLarge } = gridStyle;
  const { formFields, wysiwigLength, handleEditorChange, editorState, contentGeneralData } = useContentData();
  const coverContext = useContentsImages();

  return (
    <div className={`${col12} ${containerLarge}`}>
      <div className={`${containerSmall} ${accentLabels}`}>
        <GenericFormBuilder
          formAction={(values, props) => contentsPageParametersAction(values, contentGeneralData, coverContext, props)}
          formDeclaration={formFields}
          classname={`${col4} ${contentsStyle.contentsInputsWrapper}`}
          formClassName={col12}
          insideFormSlot={<ContentsBackgroundCover />}
          formSlot={form => (
            <>
              <ContentFormAdditional {...{ editorState, handleEditorChange, wysiwigLength, form }} />
              <div className={alignCenterOnDesktop}>{/*<ContentButton {...{ wysiwigLength, form }} />*/}</div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default ContentsPageWrapperForm;
