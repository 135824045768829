export const CONTACT_BLOCK = {
  title: 'wall.contactUs.title',
  content: {
    first: 'wall.contactUs.content.first',
    second: 'wall.contactUs.content.second'
  },
  url: {
    cta: 'wall.contactUs.cta',
    link:
      process.env.REACT_APP_ZONE === 'US'
        ? 'https://zsp61ubrh2i.typeform.com/to/ZNgpVbHw'
        : 'https://zsp61ubrh2i.typeform.com/to/kNgfGhDI'
  }
};

export const FAQ_BLOCK = {
  title: 'wall.faq.title',
  content: {
    first: 'wall.faq.content.first',
    second: 'wall.faq.content.second'
  },
  url: {
    cta: 'wall.faq.url.cta',
    link: `https://www.eu.tooodooo.com/${process.env.REACT_APP_ZONE === 'EU' ? 'fr' : ''}`
  }
};
