import React from 'react';

import style from 'assets/style/components/launch/Products.module.scss';

/**
 * Molecule component used to render the selected items count
 *
 * @param filteredItems
 * @constructor
 */
const SelectedItemsOutput = ({ filteredItems }) => (
  <div className={style.productsCount}>
    {filteredItems && !!filteredItems.length && <span>{filteredItems.length} products selected</span>}
  </div>
);

export default SelectedItemsOutput;
