import React from 'react';
import { useParams } from 'react-router';

import { getProgressActive } from 'services/LaunchServices';
import style from 'assets/style/components/wall/PageIndex.module.scss';

/**
 * Atom component used to display page index item
 *
 * @param index
 */
const PageIndexItem = ({ index }) => {
  const { stepIndex } = useParams();
  if (!index) return null;
  const stepActive = getProgressActive(stepIndex, index, style);

  return <li className={`${style.pageIndexStep} ${stepActive}`}>{index}</li>;
};

export default PageIndexItem;
