import React from 'react';

import GeneralBlock from 'components/molecules/block/GeneralBlock';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS } from 'constants/general';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';

import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import widgetStyle from 'sass-boilerplate/stylesheets/components/wall/widgets/Widget.module.scss';

/**
 * Organism component used to render contact us widget
 *
 * @constructor
 */
const ContactUsWidget = ({ title, content, url }) => {
  const { pb2, withBoldFont, pb3, withGrayAccentColor, textCenter, pointer, textSm, widthFull } = coreStyle;
  const { widgetTitle, widgetTextBody, widgetContact } = widgetStyle;
  const { colorMainButtons, colorWidgetTitle } = useSelectedProgramDesign();

  return (
    <GeneralBlock className={`${withGrayAccentColor} ${widgetContact} ${textCenter} ${wallStyle.hideBlockMobile}`}>
      <>
        <DynamicFormattedMessage
          className={`${pb2} ${textSm} ${withBoldFont} ${widgetTitle}`}
          id={title}
          tag={HTML_TAGS.P}
          style={{ color: colorWidgetTitle }}
        />
        <DynamicFormattedMessage className={`${widgetTextBody} ${textSm}`} id={content.first} tag={HTML_TAGS.P} />
        <DynamicFormattedMessage
          className={`${withBoldFont} ${pb3} ${widgetTextBody} ${textSm}`}
          id={content.second}
          tag={HTML_TAGS.P}
        />
        <DynamicFormattedMessage
          className={`${pointer} ${widthFull} ${widgetTextBody}`}
          style={{ color: colorMainButtons }}
          onClick={() => {
            (window as any).open(url.link, '_blank');
          }}
          id={url.cta}
          tag={HTML_TAGS.ANCHOR}
        />
      </>
    </GeneralBlock>
  );
};

export default ContactUsWidget;
