import React, { useContext, useMemo } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { useLocation } from 'react-router-dom';

import { UserContext } from 'components/App';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import Loading from 'components/atoms/ui/Loading';
import GeneralBlock from 'components/molecules/block/GeneralBlock';
import PaymentMethodBlockList from 'components/molecules/onboarding/PaymentMethodBlockList';
import Accordion from 'components/organisms/accordion/Accordion';
import WallCreateAccountFormWrapper from 'components/organisms/form-wrappers/updateAccountInformation/WallCreateAccountFormWrapper';
import WallSettingsChangePasswordFormWrapper from 'components/organisms/form-wrappers/WallSettingsChangePasswordFormWrapper';
import StaticSubscriptionSection from 'components/organisms/onboarding/StaticSubscriptionSection';
import WallGdprBlock from 'components/organisms/wall/WallGdprBlock';
import WallSettingsAdministrators from 'components/organisms/wall/WallSettingsAdministrators';
import { HTML_TAGS, LOADER_TYPE } from 'constants/general';
import { PLATFORM_HIERARCHIC_TYPE } from 'constants/platforms';
import { ACCOUNT, ADMINISTRATORS, CHANGE_PASSWORD, GDPR, PAYMENT } from 'constants/wall/settings';
import { useSettingsData } from 'hooks/wall/settings/useSettingsData';
import { useWallSelection } from 'hooks/wall/useWallSelection';
import {
  isUserHyperAdmin,
  getPlatformSuperUserIsLoggedInto,
  hasAtLeastSuperRole,
  isAnyKindOfManager
} from 'services/security/accessServices';
import { getUserHighestRole } from 'services/UserDataServices';

import style from 'sass-boilerplate/stylesheets/components/wall/WallSettingsBlock.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';

/**
 * Organism component used to render settings block/tabs
 *
 * @constructor
 */
const SettingsMainBlock = () => {
  const langKeyPrefix = 'wall.settings.';
  const { settingsTabList, settingsTab, settingsActiveTab } = style;
  const { withGrayAccentColor, withBoldFont, pointer, mt3, mt0, pt2, dMediumPx0 } = coreStyle;
  const { state } = useLocation<any>();
  const { userData = {}, componentLoading: loadingUserData } = useContext(UserContext);
  const highestRole = userData.highestRole || getUserHighestRole();

  const { selectedPlatform = {}, loadingPlatforms } = useWallSelection();
  const platform =
    useMemo(
      () =>
        // for super users, settings are relative to their platform (super/hyper platform)
        isUserHyperAdmin(highestRole) || hasAtLeastSuperRole(selectedPlatform.role)
          ? getPlatformSuperUserIsLoggedInto(userData)
          : selectedPlatform,
      [selectedPlatform, userData, highestRole]
    ) || selectedPlatform;
  const { index, setTabIndex, tabHeaders, hasAdminRights, userRights } = useSettingsData(platform);

  if (loadingPlatforms || loadingUserData) {
    return <Loading type={LOADER_TYPE.DROPZONE} />;
  }

  return (
    <GeneralBlock className={mt0}>
      <Tabs selectedIndex={index} onSelect={setTabIndex}>
        <TabList className={settingsTabList}>
          {tabHeaders.map(objKey => (
            <Tab
              key={objKey}
              className={`${settingsTab} ${withGrayAccentColor} ${withBoldFont}`}
              selectedClassName={settingsActiveTab}
            >
              <DynamicFormattedMessage tag={HTML_TAGS.SPAN} id={`${langKeyPrefix}${objKey}`} className={pointer} />
            </Tab>
          ))}
        </TabList>
        {tabHeaders.includes(ACCOUNT) && (
          <TabPanel>
            <GeneralBlock isShadow={false}>
              <WallCreateAccountFormWrapper />
            </GeneralBlock>
          </TabPanel>
        )}
        {tabHeaders.includes(CHANGE_PASSWORD) && (
          <TabPanel>
            <GeneralBlock isShadow={false}>
              <div className={mt3}>
                <WallSettingsChangePasswordFormWrapper />
              </div>
            </GeneralBlock>
          </TabPanel>
        )}
        {(hasAdminRights || isAnyKindOfManager(userRights)) && (
          <>
            {!isAnyKindOfManager(userRights) && tabHeaders.includes(ADMINISTRATORS) && (
              <TabPanel>
                <div className={mt3}>
                  <WallSettingsAdministrators platform={platform} />
                </div>
              </TabPanel>
            )}
            {tabHeaders.includes(PAYMENT) && (
              <TabPanel>
                <GeneralBlock className={dMediumPx0} isShadow={false}>
                  <>
                    {platform && platform.hierarchicType === PLATFORM_HIERARCHIC_TYPE.INDEPENDENT && (
                      <Accordion title={<DynamicFormattedMessage id="subscription.title" tag={HTML_TAGS.P} />}>
                        <StaticSubscriptionSection />
                      </Accordion>
                    )}

                    <Accordion
                      shouldBeOpened={state && state.fromSetCard}
                      title={<DynamicFormattedMessage id="payment.rewards.title" tag={HTML_TAGS.P} />}
                    >
                      <div className={pt2}>
                        <DynamicFormattedMessage id="payment.rewards.points.info" tag={HTML_TAGS.P} />
                        <DynamicFormattedMessage id="payment.rewards.points.label" tag={HTML_TAGS.P} />
                      </div>
                      <PaymentMethodBlockList platformId={(selectedPlatform && selectedPlatform.id) || platform.id} />
                    </Accordion>
                  </>
                </GeneralBlock>
              </TabPanel>
            )}
          </>
        )}
        {tabHeaders.includes(GDPR) && (
          <TabPanel>
            <GeneralBlock isShadow={false}>
              <WallGdprBlock />
            </GeneralBlock>
          </TabPanel>
        )}
      </Tabs>
    </GeneralBlock>
  );
};

export default SettingsMainBlock;
