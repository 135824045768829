import React from 'react';
import ContentLoader from 'react-content-loader';

/**
 * Auto generated component used as placeholder for intro block
 *
 * @constructor
 */
const IntroPlaceholder = () => (
  <ContentLoader
    speed={3}
    width={850}
    height={470}
    style={{ padding: 20 }}
    viewBox="0 0 850 470"
    backgroundColor="#f3f3f3"
    foregroundColor="#3ea475"
  >
    <rect x="-16" y="6" rx="0" ry="0" width="863" height="160" />
    <rect x="0" y="187" rx="0" ry="0" width="250" height="16" />
    <rect x="0" y="217" rx="0" ry="0" width="423" height="16" />
    <rect x="-2" y="257" rx="0" ry="0" width="254" height="27" />
    <rect x="-1" y="314" rx="0" ry="0" width="163" height="14" />
    <rect x="-2" y="354" rx="0" ry="0" width="163" height="14" />
    <rect x="2" y="388" rx="0" ry="0" width="33" height="27" />
    <rect x="50" y="387" rx="0" ry="0" width="33" height="27" />
    <rect x="93" y="387" rx="0" ry="0" width="33" height="27" />
  </ContentLoader>
);

export default IntroPlaceholder;
