import React from 'react';
import { useDispatch } from 'react-redux';

import GenericInfiniteScroll from 'components/atoms/list/GenericInfiniteScroll';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import PointConversionsHeader from 'components/molecules/wall/pointConversions/PointConversionsHeader';
import ValidatePointConversionModal from 'components/organisms/modals/ValidatePointConversionModal';
import PointConversionRow from 'components/organisms/wall/pointConversions/PointConversionRow';
import { DEFAULT_POINT_CONVERSION_STATUS_PENDING, HTML_TAGS } from 'constants/general';
import { VALIDATE_POINT_CONVERSION_MODAL } from 'constants/modal';
import { POINT_CONVERSION_HEADERS } from 'constants/wall/users';
import { setModalState } from 'store/actions/modalActions';

import tableStyle from 'sass-boilerplate/stylesheets/components/tables/Table.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import grid from 'sass-boilerplate/stylesheets/vendors/bootstrap-grid.module.scss';

/**
 * Renders the list of point conversions
 *
 * @param hasMore
 * @param loadMore
 * @param scrollRef
 * @param isLoading
 * @param pointsConversions
 * @param listCriteria
 * @param onSort
 * @param onValidateSuccess
 * @constructor
 */
export const PointConversionsList = ({
  hasMore,
  loadMore,
  scrollRef,
  isLoading,
  pointsConversions,
  listCriteria,
  onSort,
  onValidateSuccess
}) => {
  const dispatch = useDispatch();
  const { textCenter, withDangerColor, mt2, text1Xs, pointer } = coreStyle;
  const { tableRowHoverUser, tableUsers } = tableStyle;

  const onValidateRow = pointConversion => {
    dispatch(setModalState(true, VALIDATE_POINT_CONVERSION_MODAL, { pointConversion }));
  };

  return (
    <GenericInfiniteScroll
      {...{
        hasMore,
        loadMore,
        scrollRef,
        isLoading,
        height: coreStyle.h90vh
      }}
    >
      <div className={`${grid['table-responsive']} ${grid['table-responsive-md']}`}>
        <table className={`${grid['table']} ${tableRowHoverUser} ${tableUsers}`}>
          <PointConversionsHeader
            {...{ isLoading, sortState: listCriteria, onSort, headers: POINT_CONVERSION_HEADERS }}
          />
          <tbody>
            {pointsConversions.map((pointConversion, index) => {
              if (pointConversion.status === DEFAULT_POINT_CONVERSION_STATUS_PENDING) {
                return (
                  <tr key={index} onClick={() => onValidateRow(pointConversion)} className={`${text1Xs} ${pointer}`}>
                    <PointConversionRow key={`payoutField_${index}`} index={index} {...pointConversion} />
                  </tr>
                );
              }
              return (
                <tr key={index} className={text1Xs}>
                  <PointConversionRow key={`payoutField_${index}`} index={index} {...pointConversion} />
                </tr>
              );
            })}
            {!isLoading && !pointsConversions.length && (
              <DynamicFormattedMessage
                className={`${textCenter} ${withDangerColor} ${mt2}`}
                tag={HTML_TAGS.P}
                id="pointConversions.list.none"
              />
            )}
          </tbody>
        </table>
        <ValidatePointConversionModal onSuccess={onValidateSuccess} />
      </div>
    </GenericInfiniteScroll>
  );
};
