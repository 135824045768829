import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { EditorState, ContentState } from 'draft-js';

import { IStore } from 'interfaces/store/IStore';
import { useWisiwigData } from './useWisiwigData';

/**
 * Hook used to handle Editor data from Contents page in order to retreive wysiwig data
 *
 */
export const useEditorData = () => {
  const { wysiwigDataField } = useSelector((store: IStore) => store.launchReducer);
  let contentBlock = htmlToDraft('');
  if (wysiwigDataField) {
    contentBlock = htmlToDraft(draftToHtml(JSON.parse(wysiwigDataField)));
  }
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, onEditorStateChange] = useState(EditorState.createWithContent(contentState));
  const editorData = editorState.getCurrentContent();
  const { submitWysiwigData } = useWisiwigData(editorData);
  const updatedWysiwigData = EditorState.createWithContent(contentState);

  return {
    editorState,
    onEditorStateChange,
    submitWysiwigData,
    updatedWysiwigData,
    wysiwigDataField,
    editorData
  };
};
