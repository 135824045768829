import moment from 'moment';

import MomentUtilities from 'utils/MomentUtilities';
import {
  USER_DECLARATION_STATUS,
  USER_DECLARATION_SOURCE,
  ERROR_CODES,
  ACCEPTED_DECLARATION_UPLOAD_TYPES,
  ACCEPTED_DECLARATION_UPLOAD_MB_SIZE,
  UPLOAD_DECLARATIONS_ERROR_CODES
} from 'constants/api/declarations';
import { USER_DECLARATION_FIELDS } from 'constants/formDefinitions/formDeclarations';
import { DEFAULT_ISO_DATE_FORMAT, FORM_FIELDS, TIME_FORMAT } from 'constants/forms';
import { DECLARATION_PROOF_FILE_FIELD } from 'constants/formDefinitions/genericFields';
import { CHALLENGE, PROGRAM_TYPES } from 'constants/wall/launch';
import { extractErrorCode } from 'utils/api';
import { convertBytesToMb, hasExtension, convertMbToBytes } from 'utils/files';

export { getDMYDateFormat } from 'services/WallServices';

/**
 * Resolves the display settings (class, message id) corresponding to the given declaration status
 * @param declarationStatus
 * @param style
 */
export const getUserDeclarationStatusSettings = (declarationStatus: USER_DECLARATION_STATUS, style: any = {}) => {
  const { declarationRowStatusPending, declarationRowStatusDeclined, declarationRowStatusValidated } = style;

  let statusStyle = '';
  let statusDescriptionId = `userDeclaration.status.${declarationStatus}`;

  if (isPendingUserDeclarationStatus(declarationStatus)) {
    statusStyle = declarationRowStatusPending;
  }

  if (isDeclinedUserDeclationStatus(declarationStatus)) {
    statusStyle = declarationRowStatusDeclined;
  }

  if (isValidatedUserDeclarationStatus(declarationStatus)) {
    statusStyle = declarationRowStatusValidated;
    statusDescriptionId = `userDeclaration.status.${USER_DECLARATION_STATUS.VALIDATED}`;
  }

  return { statusStyle, statusDescriptionId };
};

const isValidatedUserDeclarationStatus = (declarationStatus: USER_DECLARATION_STATUS): boolean =>
  declarationStatus === USER_DECLARATION_STATUS.VALIDATED ||
  declarationStatus === USER_DECLARATION_STATUS.POINTS_ALLOCATED;

const isDeclinedUserDeclationStatus = (declarationStatus: USER_DECLARATION_STATUS): boolean =>
  declarationStatus === USER_DECLARATION_STATUS.DECLINED;

export const isPendingUserDeclarationStatus = (declarationStatus: USER_DECLARATION_STATUS): boolean =>
  declarationStatus === USER_DECLARATION_STATUS.PENDING;

/**
 * Returns the configuration for the user declaration fields to display
 * @param fieldsToDisplay
 */
export const buildDeclarationFields = (fieldsToDisplay: string[] = []) => {
  return Object.keys(USER_DECLARATION_FIELDS)
    .filter(field => fieldsToDisplay.includes(field))
    .map(field => USER_DECLARATION_FIELDS[field]);
};

/**
 * Returns the configuration for the user declaration fields to display on create
 * @param fieldsToDisplay
 * @param type
 */
export const buildDeclarationFieldsOnCreate = (fieldsToDisplay: string[] = [], type = PROGRAM_TYPES[CHALLENGE]) => {
  const fields = buildDeclarationFields(fieldsToDisplay);

  const isProofFileRequired = fieldsToDisplay.includes(FORM_FIELDS.PROOF_OF_SALE);
  if (isProofFileRequired) {
    fields.push(DECLARATION_PROOF_FILE_FIELD(isProofFileRequired, type));
  }

  return fields;
};

/**
 * Translates user declaration create error codes to message
 * @param response
 */
export const resolveUserDeclarationCreateErrorMessage = response => {
  const errorCode = extractErrorCode(response);

  if (errorCode === ERROR_CODES.PROOF_FILE_LARGE || errorCode === ERROR_CODES.PROOF_FILE_INVALID_TYPE) {
    return `wall.userDeclaration.add.error.${errorCode}`;
  }

  return 'wall.userDeclaration.add.error.general';
};

/**
 * Resolves the declaration data for given field
 * @param declaration
 * @param fieldName
 */
export const extractDeclarationDataForField = (declaration: any = {}, fieldName: string) => {
  if (fieldName === FORM_FIELDS.PRODUCT_NAME && !declaration[fieldName]) {
    return (declaration.product && declaration.product.name) || declaration.otherProductName;
  }

  if (fieldName === FORM_FIELDS.TIME_OF_SALE && !declaration[fieldName]) {
    return MomentUtilities.formatDate(declaration.dateOfEvent, TIME_FORMAT);
  }

  if (fieldName === FORM_FIELDS.DATE_OF_EVENT) {
    return declaration.dateOfEvent && moment(declaration.dateOfEvent).format(DEFAULT_ISO_DATE_FORMAT);
  }

  return declaration[fieldName];
};

export const prepareDeclarationData = (values, beneficiaryData) => {
  let dateOfEvent = values[FORM_FIELDS.DATE_OF_EVENT];
  if (values[FORM_FIELDS.TIME_OF_SALE]) {
    dateOfEvent = createDeclarationDateOfEvent(values[FORM_FIELDS.DATE_OF_EVENT], values[FORM_FIELDS.TIME_OF_SALE]);
  }
  const amount = values[FORM_FIELDS.AMOUNT] && Number(values[FORM_FIELDS.AMOUNT].replace(',', '.'));
  const quantity = Number(values[FORM_FIELDS.QUANTITY].replace(',', '.'));
  const productData = values[FORM_FIELDS.PRODUCT_NAME];

  return {
    ...values,
    ...beneficiaryData,
    ...productData,
    [FORM_FIELDS.PRODUCT_NAME]: undefined,
    amount,
    quantity,
    dateOfEvent
  };
};

/**
 * Merges declaration date and time fields into a single field
 * @param dateOfEvent
 * @param timeOfSale
 */
const createDeclarationDateOfEvent = (dateOfEvent, timeOfSale) => {
  if (!dateOfEvent) {
    return timeOfSale;
  }

  dateOfEvent.setHours(timeOfSale.getHours());
  dateOfEvent.setMinutes(timeOfSale.getMinutes());

  return dateOfEvent;
};

/**
 * Return whether given source type points to a individually created user declaration.
 * @param sourceType
 */
export const isIndividualDeclaration = (sourceType: USER_DECLARATION_SOURCE): boolean =>
  USER_DECLARATION_SOURCE.FORM === sourceType;

/**
 * Return whether given source type points to a file upload user declaration.
 * @param sourceType
 */
export const isDeclarationUpload = (sourceType: USER_DECLARATION_SOURCE): boolean =>
  USER_DECLARATION_SOURCE.FILE_UPLOAD === sourceType;

/**
 * Validates a declaration file (size and format).
 * @param file
 */
export const validateDeclarationFile = (file: File): { isValid: boolean; error?: number } => {
  if (!file) {
    return { isValid: true };
  }

  if (!hasExtension(file, ACCEPTED_DECLARATION_UPLOAD_TYPES)) {
    return {
      isValid: false,
      error: UPLOAD_DECLARATIONS_ERROR_CODES.UPLOAD_FILE_INVALID_TYPE
    };
  }

  if (convertBytesToMb(file.size) > ACCEPTED_DECLARATION_UPLOAD_MB_SIZE) {
    return { isValid: false, error: UPLOAD_DECLARATIONS_ERROR_CODES.UPLOAD_FILE_LARGE };
  }

  return { isValid: true };
};

export const getAcceptedDeclarationFileTypesAndSize = () => ({
  accept: ACCEPTED_DECLARATION_UPLOAD_TYPES.map(ext => `.${ext}`),
  maxSize: convertMbToBytes(ACCEPTED_DECLARATION_UPLOAD_MB_SIZE)
});
