import { useDispatch } from 'react-redux';
import { convertToRaw } from 'draft-js';

import { useMultiStep } from 'hooks/launch/useMultiStep';
import { setLaunchDataStep } from 'store/actions/launchActions';
import { WISYWIG_DATA_FIELD } from 'constants/wall/launch';

/**
 * Hook used to handle Wysiwig data from Contents page
 *
 * @param selectedFields
 */
export const useWisiwigData = wysiwigData => {
  const dispatch = useDispatch();
  const {
    stepSet: { setNextStep, setResultStep }
  } = useMultiStep();

  const submitWysiwigData = () => {
    dispatch(setLaunchDataStep({ key: WISYWIG_DATA_FIELD, value: JSON.stringify(convertToRaw(wysiwigData)) }));
    setNextStep();
  };

  return { setResultStep, submitWysiwigData };
};
