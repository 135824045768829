import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../App';
import LeftSideLayout from 'components/organisms/layouts/LeftSideLayout';
import { PointConversionsList } from 'components/organisms/wall/pointConversions/PointConversionsList';
import { WALL_ROUTE } from 'constants/routes';
import { WALL_TYPE } from 'constants/general';
import { usePointConversionsPage } from 'hooks/pointConversions/usePointConversionsPage';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';
import { isUserHyperAdmin } from 'services/security/accessServices';

import tableStyle from 'sass-boilerplate/stylesheets/components/tables/Table.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import bootstrap from 'sass-boilerplate/stylesheets/vendors/bootstrap-grid.module.scss';

/**
 * Component used for rendering point conversions table for hyper admins
 *
 * @constructor
 */
const PointConversionPage = () => {
  const history = useHistory();
  const { userData = {} } = useContext(UserContext);
  const {
    pointsConversions,
    hasMore,
    isLoading,
    loadMore,
    scrollRef,
    listCriteria,
    onSort,
    onValidateSuccess
  } = usePointConversionsPage();
  const { colorSidebar } = useSelectedProgramDesign();

  if (userData.highestRole && !isUserHyperAdmin(userData.highestRole)) {
    history.push(WALL_ROUTE);
    return null;
  }

  return (
    <LeftSideLayout theme={WALL_TYPE} hasUserIcon>
      <div className={`${tableStyle.table}  ${coreStyle.px3}`}>
        <div
          className={`${tableStyle.tableHeaderResponsiveMobile} ${coreStyle.py3} ${bootstrap['text-white']} ${tableStyle.tablePage} ${coreStyle.px6} ${coreStyle['flex-space-between']}`}
          style={{ backgroundColor: colorSidebar }}
        />
        <PointConversionsList
          {...{ hasMore, loadMore, scrollRef, isLoading, pointsConversions, listCriteria, onSort, onValidateSuccess }}
        />
      </div>
    </LeftSideLayout>
  );
};

export default PointConversionPage;
