import { DEFAULT_WALL_ALL_PROGRAMS } from 'constants/wall/programButtons';

/**
 * Maps the given platforms to the required format for storing data in store
 * @param allRankings
 * @param programId
 * @param platformId
 */
export const mapUserRankings = (allRankings, programId, platformId) => {
  const currentPlatform = allRankings.find(({ id }) => id === platformId) || ({} as any);
  let selectedRanking = {
    id: currentPlatform.id,
    rank: currentPlatform.averageRank,
    nameId: DEFAULT_WALL_ALL_PROGRAMS
  };
  if (programId) {
    selectedRanking = (currentPlatform.programs || []).find(({ id }) => id === programId) || {};
  }

  return {
    selectedRanking,
    allRankings,
    programRankings: currentPlatform.programs || []
  };
};

/**
 * Maps the given platforms to the required format for storing data in store
 * @param platforms
 */
export const mapBeneficiaryPointsPrograms = platforms => ({
  selectedBeneficiaryPoints: {},
  totalPlatformsPoints: platforms.map(platform => {
    return { points: platform.points, id: platform.id };
  }),
  platformProgramsPointsList: platforms.flatMap(platform =>
    platform.programs.map(program => {
      program.platformId = platform.id;
      program.totalPoints = platform.points;
      return program;
    })
  )
});

/**
 * Maps the given platforms to the required format for storing data in store
 * @param selectedPlatformId
 * @param programId
 * @param platformProgramsPointsList
 * @param totalPoints
 */
export const mapSelectedBeneficiaryPoints = (
  selectedPlatformId,
  programId,
  platformProgramsPointsList,
  totalPoints
) => {
  if (!programId) {
    const selectedBeneficiaryPlatformData =
      platformProgramsPointsList.find(({ platformId }) => selectedPlatformId === platformId) || {};
    return {
      platformProgramsPointsList,
      totalPoints,
      selectedBeneficiaryPoints: {
        id: selectedPlatformId,
        points: selectedBeneficiaryPlatformData.totalPoints
      }
    };
  }

  return {
    platformProgramsPointsList,
    totalPoints,
    selectedBeneficiaryPoints:
      platformProgramsPointsList.find(({ platformId, id }) => selectedPlatformId === platformId && id === programId) ||
      {}
  };
};

/**
 * Returns the number of points for selected platform
 * @param totalPoints
 * @param id
 * @param selectedBeneficiaryPoints
 */
export const getPlatformTotalPoints = (totalPoints, id, selectedBeneficiaryPoints) => {
  if (selectedBeneficiaryPoints.points) {
    return selectedBeneficiaryPoints.points;
  }

  return 0;
};

/**
 * Returns the current program points
 *
 * @param adminPointsData
 * @param selectedProgramId
 */
export const getCurrentProgramPoints = (adminPointsData, selectedProgramId) => {
  const currentPoints = adminPointsData.platforms.programs.find(program => program.id === selectedProgramId);
  if (currentPoints) {
    return currentPoints.points;
  }

  return 0;
};
