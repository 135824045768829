import React from 'react';

import AddExistingProductItem from 'components/molecules/launch/products/AddExistingProductItem';
import Button from 'components/atoms/ui/Button';
import { PRODUCTS_CTA_TYPE } from 'constants/wall/launch';
import { BUTTON_MAIN_TYPE } from 'constants/ui';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';

import style from 'assets/style/components/launch/Products.module.scss';

/**
 * Molecule component used to render existing product list
 * @param productList
 * @param productListState
 * @param handleItemSelection
 * @param productIds
 * @constructor
 */
const AddExistingItems = ({ productList, handleItemSelection, productIds }) => {
  const { ADD, REMOVE } = PRODUCTS_CTA_TYPE;
  const { SECONDARY, ALT } = BUTTON_MAIN_TYPE;

  return (
    <div className={style.addExistingProductsView}>
      <>
        {productList.map(({ name, id, picturePath }) => {
          const isItemSelected = productIds && productIds.includes(id);

          return (
            <AddExistingProductItem key={id} {...{ id, name, picturePath, handleItemSelection }}>
              <DynamicFormattedMessage
                type={isItemSelected ? SECONDARY : ALT}
                tag={Button}
                id={`launchProgram.products.alreadySelected.${isItemSelected ? REMOVE : ADD}`}
                onClick={() => handleItemSelection(id)}
              />
            </AddExistingProductItem>
          );
        })}
      </>
    </div>
  );
};

export default AddExistingItems;
