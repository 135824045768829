import React, { createContext } from 'react';

import LaunchProgramTitle from 'components/molecules/launch/LaunchProgramTitle';
import ContentsPageWrapperForm from 'components/organisms/form-wrappers/ContentPageWrapperForm';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/store/IStore';
import { useContentPageData } from 'hooks/launch/contents/useContentPageData';
import { redirectToFirstStep } from 'services/LaunchServices';

import style from 'assets/style/components/launch/Launch.module.scss';

export const ContentsCoverContext = createContext(null);

/**
 * Template component used to render Contents Step page
 *
 * @constructor
 */
const ContentsPage = () => {
  const { brandSubtitle, launchHeadingExtraInfo } = style;
  const { contentCoverConfig } = useContentPageData();
  const { confidentiality, type } = useSelector((store: IStore) => store.launchReducer);

  if (!confidentiality || !type) redirectToFirstStep();

  return (
    <ContentsCoverContext.Provider value={contentCoverConfig}>
      <LaunchProgramTitle
        titleId="launchProgram.title"
        subtitleId="launchProgram.contents.title"
        subtitleCustomClass={brandSubtitle}
      />
      <DynamicFormattedMessage id="launchProgram.contents.info" tag="p" className={launchHeadingExtraInfo} />
      <ContentsPageWrapperForm />
    </ContentsCoverContext.Provider>
  );
};

export default ContentsPage;
