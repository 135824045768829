import React from 'react';

import HyperPlatformBlock from 'components/molecules/programs/HyperPlatformBlock';
import PlatformPrograms from 'components/molecules/programs/PlatformPrograms';
import GenericInfiniteScroll from 'components/atoms/list/GenericInfiniteScroll';
import { emptyFn } from 'utils/general';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS } from 'constants/general';
import { hasAdminRights } from 'services/security/accessServices';

import grid from 'sass-boilerplate/stylesheets/vendors/bootstrap-grid.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import componentStyle from 'sass-boilerplate/stylesheets/components/wall/Programs.module.scss';

const HyperProgramsList = ({
  individualPlatforms,
  nestedSuperPlatforms,
  hasMore,
  isLoading,
  handleLoadMore,
  enableOnly,
  setParentPlatform: onSelect
}) => {
  const { mb3, overflowHidden } = coreStyle;
  const { blocksWrapper, separator, customCol, customHeight } = componentStyle;
  const disableProgramBlocks = !!enableOnly;
  const blockClassName = `${grid['col-xl-4']} ${grid['col-lg-6']} ${grid['col-md-6']} ${customCol} ${mb3}`;

  return (
    <div>
      <GenericInfiniteScroll
        {...{
          hasMore,
          isLoading,
          loadMore: handleLoadMore,
          height: '0',
          className: `${overflowHidden} ${customHeight}`
        }}
      >
        {nestedSuperPlatforms.map((platform, index) => {
          const canManagePlatform = hasAdminRights(platform);

          return (
            <div key={`platform_${platform.id}_${index}`} className={blocksWrapper}>
              <div className={`${grid.row} ${separator}`}>
                <div className={blockClassName}>
                  <HyperPlatformBlock {...{ platform, canManagePlatform, enableOnly, onSelect }} />
                </div>
                {platform.subPlatforms &&
                  platform.subPlatforms.map(subPlatform => (
                    <>
                      <div className={blockClassName} key={`subplatform_${subPlatform.id}`}>
                        <HyperPlatformBlock {...{ platform: subPlatform, canManagePlatform, enableOnly, onSelect }} />
                      </div>
                      <PlatformPrograms
                        {...{ platform: subPlatform, isDisabled: disableProgramBlocks, blockClassName }}
                      />
                    </>
                  ))}
                <PlatformPrograms {...{ platform, isDisabled: disableProgramBlocks, blockClassName }} />
              </div>
            </div>
          );
        })}
        <div className={blocksWrapper}>
          <div className={grid.row}>
            {individualPlatforms.length && (
              <div className={blockClassName}>
                <HyperPlatformBlock
                  {...{
                    platform: {
                      name: <DynamicFormattedMessage id="platforms.placeholder.independent" tag={HTML_TAGS.SPAN} />
                    },
                    enableOnly,
                    onSelect: emptyFn
                  }}
                />
              </div>
            )}
            {individualPlatforms.map((platform, index) => (
              <>
                <div className={blockClassName} key={`single_platform_${platform.id}_${index}`}>
                  <HyperPlatformBlock
                    {...{ platform, canManagePlatform: hasAdminRights(platform), enableOnly, onSelect }}
                  />
                </div>
                <PlatformPrograms {...{ platform, isDisabled: disableProgramBlocks, blockClassName }} />
              </>
            ))}
          </div>
        </div>
      </GenericInfiniteScroll>
    </div>
  );
};

export default HyperProgramsList;
