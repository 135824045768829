import { useEffect, useState } from 'react';
import { useWallSelection } from 'hooks/wall/useWallSelection';
import { isUserBeneficiary } from 'services/security/accessServices';
import { LOGIN, ONBOARDING_BENEFICIARY_LOGIN_ROUTE } from 'constants/routes';
import ProgramsApi from '../../api/ProgramsApi';
import { retrieveOnboardingBeneficiaryCookie, storeOnboardingBeneficiaryCookie } from '../../utils/LocalStorageUtils';

const programsApi = new ProgramsApi();

/**
 * Hook used to return the user logout redirect route
 */
export const useLogoutUserRoute = () => {
  const [logoutRedirectRoute, setLogoutRedirectRoute] = useState(LOGIN);
  const {
    selectedPlatform: { role },
    selectedProgramId,
    programDetails
  } = useWallSelection();
  const isBeneficiary = isUserBeneficiary(role);
  const storedBeneficiaryData = retrieveOnboardingBeneficiaryCookie();

  useEffect(() => {
    let logoutTo = `/${LOGIN}`;
    if (isBeneficiary) {
      logoutTo = ONBOARDING_BENEFICIARY_LOGIN_ROUTE;
      if (!Object.keys(storedBeneficiaryData.design).length) {
        (async () => {
          const data = await programsApi.getAnonymousProgramDetails(parseInt(selectedProgramId));
          data.design = data.design || {};
          storeOnboardingBeneficiaryCookie(data);
        })();
      }
    }
    setLogoutRedirectRoute(logoutTo);
  }, [isBeneficiary, selectedProgramId, programDetails]);

  return { logoutRedirectRoute };
};
