import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

/**
 * Method gets the current index of the active step
 *
 * @param launchSteps
 */
export const useActiveStep = launchSteps => {
  const { step } = useParams();
  const [currentStepIndex, setCurrentStep] = useState(0);

  useEffect(() => setCurrentStep(launchSteps.indexOf(step)), [step, launchSteps]);

  return currentStepIndex;
};
