import React from 'react';
import Slider from 'rc-slider';

import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { AVATAR_EDITOR_CONFIG } from 'constants/personalInformation';
import { HTML_TAGS } from 'constants/general';

import style from 'assets/style/components/PersonalInformation/AvatarCreator.module.scss';

/**
 * Molecule component used to render a single slider control with label
 *
 * @param value
 * @param setValue
 * @param type
 * @param config
 * @param avatarConfig
 * @constructor
 *
 * @see SliderControlStory
 */
const SliderControl = ({ value, type, config, setValue }) => {
  const { sliderControl, sliderLabel } = style;

  return (
    <div className={sliderControl}>
      <DynamicFormattedMessage
        tag={HTML_TAGS.SPAN}
        className={sliderLabel}
        id={`personalInformation.info.slider.${type}`}
      />
      <Slider
        min={config.min}
        max={config.max}
        step={AVATAR_EDITOR_CONFIG.SLIDER_STEP}
        value={value[type]}
        onChange={newValue => setValue({ ...value, [type]: newValue })}
      />
    </div>
  );
};

export default SliderControl;
