import React from 'react';
import { FormattedMessage } from 'react-intl';

import ContentsPageEditor from 'components/atoms/launch/contents/ContentPageEditor';
import Button from 'components/atoms/ui/Button';

import buttonStyle from 'assets/style/common/Button.module.scss';
import style from 'assets/style/components/wall/GeneralWallStructure.module.scss';

/**
 * Molecule component used to render Contents Form Additional elements
 *
 * @constructor
 */
const ContentFormAdditional = ({ editorState, handleEditorChange, wysiwigLength, form }) => {
  const isDisabled = !(wysiwigLength && form.values.contentsTitle.trim());

  return (
    <>
      <ContentsPageEditor {...{ editorState, handleEditorChange, wysiwigLength, form }} />
      <div className={style.alignCenterOnDesktop}>
        <Button role="submit" loading={form.isSubmitting} className={`${isDisabled && buttonStyle.disabled}`}>
          <FormattedMessage id="form.submit.next" />
        </Button>
      </div>
    </>
  );
};

export default ContentFormAdditional;
