import React from 'react';
import useRouter from 'use-react-router';

import LeftNavigationElement from 'components/atoms/wall/LeftNavigationElement';
import LogoutButton from 'components/atoms/wall/LogoutButton';
import envConfig from 'config/envConfig';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { WALL } from 'services/wall/navigation';
import { useWindowSize } from 'hooks/others/useWindowSize';
import { WINDOW_SIZES, HTML_TAGS } from 'constants/general';
import { useNavItems } from 'hooks/nav/useNavItems';
import { openLegal } from 'utils/general';

import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import style from 'assets/style/components/wall/LeftNavigation.module.scss';

/**
 * Molecule component used to render left wall navigation
 * @param closeNav
 * @param showCompanyLogo
 * @param classname
 * @constructor
 */
const WallLeftNavigation = ({ closeNav, showCompanyLogo }) => {
  const { mt0, tLandscapeMt7, mtAuto } = coreStyle;
  const { menuSeparator, navigationStyle, wallLink } = style;
  const router = useRouter();
  const { windowSize } = useWindowSize();
  const { wall, widgets } = useNavItems();

  return (
    <>
      <div className={navigationStyle}>
        <ul className={showCompanyLogo ? mt0 : ''}>
          {wall.map(({ title, icon, url, external, isDisabled }) => (
            <LeftNavigationElement key={title} {...{ title, icon, url, closeNav, external, isDisabled }} />
          ))}
        </ul>
        {windowSize.width < WINDOW_SIZES.DESKTOP_SMALL && router.match.path.includes(WALL) && (
          <>
            <hr className={menuSeparator} />
            <ul>
              {widgets.map(({ title, url }) => (
                <LeftNavigationElement className={wallLink} key={title} {...{ title, url, closeNav }} />
              ))}
            </ul>
          </>
        )}
      </div>
      <div className={`${navigationStyle} ${mtAuto} ${tLandscapeMt7}`}>
        <li className={wallLink}>
          <DynamicFormattedMessage
            tag={HTML_TAGS.ANCHOR}
            onClick={event => openLegal(event)}
            id="onboarding.menu.legal"
            href={envConfig.onboarding.legalUrl}
          />
        </li>
        <LogoutButton />
      </div>
    </>
  );
};

export default WallLeftNavigation;
