import React from 'react';

import DatePickerMultiple from 'components/molecules/forms/fields/DatepickerMultiple/DatePickerMultiple';
import DatePickerDefault from 'components/molecules/forms/fields/DatePickerDefault';
import DefaultInputField from 'components/molecules/forms/fields/DefaultInputField';
import DropdownInputField from 'components/molecules/forms/fields/DropdownInputField';
import DynamicDatePickerDefault from 'components/molecules/forms/fields/DynamicDatePickerDefault';
import RadioButtonInputField from 'components/molecules/forms/fields/RadioButtonInputField';
import RadioTextInputField from 'components/molecules/forms/fields/RadioTextInputField';
import ExtendedInputField from './ExtendedInputField';
import FileInputField from 'components/molecules/forms/fields/FileInputField';
import DeclarationProductField from 'components/molecules/forms/fields/DeclarationProductField';
import { INPUT_TYPE } from 'constants/forms';

/**
 * Template component used to render a input field
 *
 * @param form
 * @param field
 * @constructor
 */
const CustomFormField = ({ form, field, name = '' }) => {
  switch (field.type) {
    case INPUT_TYPE.DROPDOWN:
      return <DropdownInputField {...{ field, form }} />;
    case INPUT_TYPE.RADIO:
      return <RadioButtonInputField {...{ field, form }} />;
    case INPUT_TYPE.DATETIME:
      return <DatePickerDefault {...{ field, form }} />;
    case INPUT_TYPE.RADIO_TEXT:
      return <RadioTextInputField {...{ field, form }} />;
    case INPUT_TYPE.DYNAMIC_DATETIME:
      return <DynamicDatePickerDefault {...{ field, form }} />;
    case INPUT_TYPE.MULTIPLE_DATETIME:
      return <DatePickerMultiple {...{ field, form }} />;
    case INPUT_TYPE.EXTENDED_INPUT_FIELD:
      return <ExtendedInputField {...{ field, form }} />;
    case INPUT_TYPE.FILE:
      return <FileInputField {...{ field, form }} />;
    case INPUT_TYPE.DECLARATION_PRODUCT:
      return <DeclarationProductField {...{ field, form }} />;
    default:
      return <DefaultInputField {...{ field, form, name }} />;
  }
};

export default CustomFormField;
