import { useMemo } from 'react';

import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';
import { extractCubeAllocationMechanisms, getStyleForGoalDetails } from 'services/CubeServices';

/**
 * Hook used to handle cube data
 * @param cube
 * @param products
 * @param onLaunch
 */
export const useCubeGoalsDetails = (cube, products, onLaunch) => {
  const cubeData = cube || {};
  const designColors = useSelectedProgramDesign(onLaunch);
  const productNamesById = useMemo(
    () =>
      products.reduce((acc, product) => {
        acc[product.id] = product.name;
        return acc;
      }, {}),
    [products]
  );

  const cubeMechanisms = useMemo(() => extractCubeAllocationMechanisms(cubeData), [cubeData]);
  const style = useMemo(() => getStyleForGoalDetails(designColors), [designColors]);

  const { goals = [], correlatedGoals, correlated } = cubeData;

  return {
    goals,
    correlatedGoals: correlatedGoals || correlated,
    productNamesById,
    cubeMechanisms,
    style
  };
};
