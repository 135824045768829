import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useActiveStep } from './useActiveStep';
import { useStepHandler } from './useStepHandler';
import { getActiveSteps, getAvailableStatus } from 'services/LaunchServices';
import { IStore } from 'interfaces/store/IStore';
import {
  QUICK_LAUNCH_AVAILABLE_STEPS,
  FULL_LAUNCH_AVAILABLE_STEPS,
  FREEMIUM_FULL_LAUNCH_AVAILABLE_STEPS,
  FREEMIUM,
  FREEMIUM_QUICK_LAUNCH_AVAILABLE_STEPS
} from 'constants/wall/launch';
import { QUICK } from 'constants/general';
import { useBackHandles } from 'hooks/launch/steps/useBackHandles';

/**
 * Hook used to handle all logic for multi step wizard
 */
export const useMultiStep = () => {
  const launchStoreData = useSelector((store: IStore) => store.launchReducer);
  const params: any = useParams();
  const { stepIndex } = params;
  const { programJourney, type } = launchStoreData;
  const quickProgramJourney = programJourney === QUICK;

  let launchStep = quickProgramJourney ? QUICK_LAUNCH_AVAILABLE_STEPS : FULL_LAUNCH_AVAILABLE_STEPS;
  if (type === FREEMIUM) {
    launchStep = quickProgramJourney ? FREEMIUM_QUICK_LAUNCH_AVAILABLE_STEPS : FREEMIUM_FULL_LAUNCH_AVAILABLE_STEPS;
  }

  const launchSteps = getActiveSteps(launchStep);
  const currentStepIndex = useActiveStep(launchSteps);
  const stepSet = useStepHandler(currentStepIndex, launchSteps);
  const stepAvailable = getAvailableStatus(currentStepIndex, launchSteps, stepIndex);
  const { handleBackStep } = useBackHandles(stepSet, params);

  return { stepSet, stepAvailable, handleBackStep };
};
