import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { filterAllocationTypes } from 'services/CubeServices';
import { ALLOCATION_TYPES, MEASUREMENT_TYPES } from 'constants/wall/launch';
import { IStore } from 'interfaces/store/IStore';
import { setFilteredAllocationType } from 'store/actions/launchActions';

/**
 * Hook used to handle allocation filter
 *
 * @param index
 */
export const useCubeAllocationTypeFilter = index => {
  const dispatch = useDispatch();
  const { cube } = useSelector((store: IStore) => store.launchReducer);
  const currentGoal = cube.goals[index];

  useEffect(() => {
    const filteredAllocationTypes = filterAllocationTypes(
      ALLOCATION_TYPES,
      cube.acceptedCorrelatedGoals,
      currentGoal.measurementType || MEASUREMENT_TYPES.QUANTITY,
      index
    );
    setFilteredAllocationType(index, filteredAllocationTypes, dispatch, cube);
  }, [currentGoal.measurementType]);
};
