import React from 'react';

import CubeOption from 'components/atoms/launch/cube/CubeOption';
import { MEASUREMENT_TYPES } from 'constants/wall/launch';
import { useCubeMeasurementTypeFilter } from 'hooks/launch/cube/useCubeMeasurementTypeFilter';

import style from 'sass-boilerplate/stylesheets/components/launch/Cube.module.scss';

/**
 * Molecule component used to render mechanism type options
 *
 * @param handleSelection
 * @param measurementType
 * @param index
 * @constructor
 */
const MechanismTypeOptions = ({ handleMethodMechanismSelection, measurementType, index, type }) => {
  const { currentMeasurementTypes } = useCubeMeasurementTypeFilter(index);

  return (
    <div className={style.cubeRadio}>
      {currentMeasurementTypes.map(key => (
        <CubeOption
          key={key}
          {...{
            type: MEASUREMENT_TYPES[key],
            handleSelection: handleMethodMechanismSelection,
            isSelected: measurementType === MEASUREMENT_TYPES[key],
            index,
            translation: `launchProgram.cube.${type}.${MEASUREMENT_TYPES[key]}`
          }}
        />
      ))}
    </div>
  );
};

export default MechanismTypeOptions;
