import React from 'react';
import { Link } from 'react-router-dom';

import GeneralBlock from 'components/molecules/block/GeneralBlock';
import Loading from 'components/atoms/ui/Loading';
import useDashboardNumber from 'hooks/wall/useDashboardNumber';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS, LOADER_TYPE } from 'constants/general';
import { DASHBOARD_ROUTE, LAUNCH_BASE, WALL_BENEFICIARY_POINTS_ROUTE } from 'constants/routes';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';
import { useWallRoute } from 'hooks/wall/useWallRoute';
import { numberWithSpaces } from 'utils/general';

import style from 'sass-boilerplate/stylesheets/components/wall/WallUserBlock.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import useSelectedProgram from '../../../../hooks/wall/useSelectedProgram';
import { FREEMIUM, PROGRAM_TYPES } from '../../../../constants/wall/launch';
import { useUserRole } from '../../../../hooks/user/useUserRole';
import { getUserAuthorizations, isAnyKindOfManager } from '../../../../services/security/accessServices';

/**
 * Molecule component used to render dashboard block
 * @constructor
 */
const DashboardWidget = () => {
  const { userBlock, userBlockTitle, userBlockLink, userBlockCount, userBlockMatrix, userBlockNumbers } = style;
  const { overlayBeforeWhite, withSecondaryColor } = coreStyle;
  const { points, isBeneficiary, isPointsComponentLoading } = useDashboardNumber();
  const { colorMainButtons, colorTitle, colorWidgetTitle } = useSelectedProgramDesign();
  const { isWallRoute, isCommunicationRoute } = useWallRoute();
  const selectedProgram = useSelectedProgram();
  const userRole = useUserRole();
  const userAccess = getUserAuthorizations(userRole);
  const isManager = isAnyKindOfManager(userAccess);
  const isFreemium = selectedProgram && selectedProgram.programType === PROGRAM_TYPES[FREEMIUM];

  let blockTitleId = 'wall.dashboard.block.points.adminAndManager',
    linkToId = 'wall.see.all.matrix',
    route = DASHBOARD_ROUTE;
  let value = isFreemium ? 0 : numberWithSpaces(points);

  if (isBeneficiary) {
    blockTitleId = 'wall.dashboard.block.points.beneficiary';
    linkToId = 'wall.see.all.points';
    route = WALL_BENEFICIARY_POINTS_ROUTE;
  } else {
    if (isFreemium && !isManager) {
      blockTitleId = 'wall.dashboard.block.points.beneficiary';
      linkToId = 'wall.freemium.launch';
      route = LAUNCH_BASE;
      value = (
        <DynamicFormattedMessage
          style={{ textTransform: 'uppercase' }}
          id="wall.dashboard.block.freemium.start"
          tag={HTML_TAGS.SPAN}
        />
      );
    }
  }

  return (
    <GeneralBlock
      tooltipId="disabledWidget"
      className={`${userBlock} ${userBlockNumbers} ${(isBeneficiary || isManager) && isFreemium && overlayBeforeWhite}
       ${!isBeneficiary && userBlockMatrix} ${wallStyle.hideBlockMobile}`}
    >
      <DynamicFormattedMessage
        style={{ color: colorWidgetTitle }}
        className={userBlockTitle}
        id={blockTitleId}
        tag={HTML_TAGS.SPAN}
      />
      {(isPointsComponentLoading && (
        <div className={userBlockCount}>
          <Loading className={withSecondaryColor} type={LOADER_TYPE.DROPZONE} />
        </div>
      )) || (
        <p className={userBlockCount} style={{ color: isWallRoute || isCommunicationRoute ? colorTitle : '' }}>
          {value}
        </p>
      )}
      <DynamicFormattedMessage
        tag={Link}
        to={route}
        className={userBlockLink}
        id={linkToId}
        style={{ color: isWallRoute || isCommunicationRoute ? colorMainButtons : '' }}
      />
    </GeneralBlock>
  );
};

export default DashboardWidget;
