import React from 'react';

import { HTML_TAGS } from 'constants/general';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';

import style from 'sass-boilerplate/stylesheets/components/launch/Cube.module.scss';

/**
 * Atom component used to render cube option radio
 *
 * @param isSelected
 * @param handleSelection
 * @param index
 * @param type
 * @param translation
 * @constructor
 */
const CubeOption = ({ isSelected, handleSelection, index = null, type, translation }) => {
  const { cubeRadioItem, cubeRadioItemSelected } = style;

  return (
    <DynamicFormattedMessage
      className={`${cubeRadioItem} ${isSelected ? cubeRadioItemSelected : ''}`}
      tag={HTML_TAGS.SPAN}
      id={translation}
      onClick={() => handleSelection(index, type)}
    />
  );
};

export default CubeOption;
