import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import GeneralBlock from 'components/molecules/block/GeneralBlock';
import useUserDeclarations from 'hooks/wall/useUserDeclarations';
import Loading from 'components/atoms/ui/Loading';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS, LOADER_TYPE } from 'constants/general';
import { LAUNCH_BASE, USER_DECLARATIONS_ROUTE, WALL_BENEFICIARY_DECLARATIONS_ROUTE } from 'constants/routes';
import { createDeclarationLinesLabels } from 'services/WallServices';
import { useWallRoute } from 'hooks/wall/useWallRoute';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';

import style from 'sass-boilerplate/stylesheets/components/wall/WallUserDeclarationsBlock.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import useSelectedProgram from '../../../../hooks/wall/useSelectedProgram';
import { FREEMIUM, PROGRAM_TYPES } from '../../../../constants/wall/launch';
import { useUserRole } from '../../../../hooks/user/useUserRole';
import { getUserAuthorizations, isAnyKindOfManager } from '../../../../services/security/accessServices';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../interfaces/store/IStore';
import { DEFAULT_ALL_PROGRAMS } from '../../../../constants/wall/programButtons';

/**
 * Molecule component used to render user declarations block
 * @constructor
 */
const UserDeclarationsWidget = () => {
  const intl = useIntl();
  const { programs } = useSelector((store: IStore) => store.wallReducer);
  const { currentUserDeclarations, isBeneficiary, isLoading } = useUserDeclarations();
  const { withGrayAccentColor, withGrayColor, withFontMedium, overlayBeforeWhite, pb2, pt2, mb07, pb4 } = coreStyle;
  const { isWallRoute, isCommunicationRoute } = useWallRoute();
  const { colorMainButtons, colorWidgetTitle } = useSelectedProgramDesign();
  const {
    userDeclarationBlock,
    userDeclarationBlockTitle,
    userDeclarationBlockLink,
    userDeclarationBlockFreemium
  } = style;
  const selectedProgram = useSelectedProgram();
  const isFreemium = selectedProgram && selectedProgram.programType === PROGRAM_TYPES[FREEMIUM];
  const userRole = useUserRole();
  const userAccess = getUserAuthorizations(userRole);
  const isManager = isAnyKindOfManager(userAccess);
  let linkTranslation = 'wall.userDeclarations.block.all';
  const hasOnlyFreemiumPrograms = !programs.some(
    program => program.name !== DEFAULT_ALL_PROGRAMS && program.programType !== PROGRAM_TYPES[FREEMIUM]
  );

  if (isFreemium && !isManager) linkTranslation = 'wall.freemium.launch';
  if (isBeneficiary) linkTranslation = 'wall.userDeclarations.block.allBeneficiary';

  const getMidContent = () => {
    if (isLoading) {
      return (
        <div className={pt2}>
          <Loading className={coreStyle.withSecondaryColor} type={LOADER_TYPE.DROPZONE} />
        </div>
      );
    }

    if (isFreemium && !(isBeneficiary || isManager)) {
      return (
        <p className={userDeclarationBlockFreemium}>
          <DynamicFormattedMessage
            style={{ textTransform: 'uppercase' }}
            id="wall.dashboard.block.freemium.start"
            tag={HTML_TAGS.SPAN}
          />
        </p>
      );
    }

    if (!currentUserDeclarations.length || isFreemium) {
      return (
        <p className={pb4}>
          <DynamicFormattedMessage
            className={`${withGrayAccentColor} ${withFontMedium}`}
            id="wall.userDeclarations.block.none"
            tag={HTML_TAGS.SPAN}
          />
        </p>
      );
    }

    return (
      <div className={`${withGrayAccentColor} ${pb2} ${pt2}`}>
        {createDeclarationLinesLabels(currentUserDeclarations, intl).map((label, index) => (
          <p className={`${withFontMedium} ${mb07}`} key={index}>
            {label}
          </p>
        ))}
      </div>
    );
  };
  const showOverlay =
    (isBeneficiary && hasOnlyFreemiumPrograms && selectedProgram && selectedProgram.name === DEFAULT_ALL_PROGRAMS) ||
    ((isManager || isBeneficiary) && isFreemium);

  return (
    <GeneralBlock
      tooltipId="disabledWidget"
      className={`${userDeclarationBlock} ${wallStyle.hideBlockMobile} 
      ${showOverlay && overlayBeforeWhite}`}
    >
      <DynamicFormattedMessage
        className={`${userDeclarationBlockTitle} ${withGrayColor}`}
        id="wall.userDeclarations.block.userStatements"
        style={{ color: colorWidgetTitle }}
        tag={HTML_TAGS.SPAN}
      />
      {getMidContent()}
      <DynamicFormattedMessage
        tag={Link}
        to={isBeneficiary ? WALL_BENEFICIARY_DECLARATIONS_ROUTE : isFreemium ? LAUNCH_BASE : USER_DECLARATIONS_ROUTE}
        className={userDeclarationBlockLink}
        id={linkTranslation}
        style={{ color: isWallRoute || isCommunicationRoute ? colorMainButtons : '' }}
      />
    </GeneralBlock>
  );
};

export default UserDeclarationsWidget;
