import React from 'react';

import GoalMoreProducts from 'components/atoms/wall/rewards/GoalMoreProducts';
import ArrayUtilities from 'utils/ArrayUtilities';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS } from 'constants/general';
import { GOAL_PRODUCTS_TO_DISPLAY } from 'constants/wall/launch';

/**
 * Component used to render products linked to goal
 * @param productNamesById
 * @param productIds
 * @constructor
 */
const GoalProducts = ({ productNamesById, productIds }) => {
  if (!ArrayUtilities.isNonEmptyArray(productIds)) {
    return null;
  }

  const productsToDisplay = productIds.slice(0, GOAL_PRODUCTS_TO_DISPLAY + 1);
  const productsCount = productsToDisplay.length;

  return (
    <DynamicFormattedMessage
      tag={HTML_TAGS.P}
      id="wall.intro.rewards.products.layout"
      values={{
        products: productsToDisplay.map((id, index) => (
          <DynamicFormattedMessage
            key={`goal_product_${id}_${index}`}
            id="wall.intro.rewards.products"
            tag={HTML_TAGS.SPAN}
            values={{
              product:
                index === GOAL_PRODUCTS_TO_DISPLAY ? (
                  <GoalMoreProducts moreProductsCount={productIds.length - GOAL_PRODUCTS_TO_DISPLAY} />
                ) : (
                  <strong>{productNamesById[id]}</strong>
                ),
              position: index === productsCount - 1 && productsCount > 1 ? 'last' : index === 0 ? 'first' : 'inner'
            }}
          />
        ))
      }}
    />
  );
};

export default GoalProducts;
