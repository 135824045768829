import { useSelector, useDispatch } from 'react-redux';

import { buildContentsFormField } from 'services/LaunchServices';
import { IStore } from 'interfaces/store/IStore';
import { useEditorData } from './useEditorData';
import { useMultiStep } from 'hooks/launch/useMultiStep';

/**
 * Hook used to handle Content data from Contents page in order to create Form
 *
 */
export const useContentData = () => {
  const dispatch = useDispatch();
  const {
    stepSet: { setNextStep }
  } = useMultiStep();
  const { contentsTitle, bannerTitle } = useSelector((store: IStore) => store.launchReducer);
  const formFields = buildContentsFormField({ contentsTitle, bannerTitle });
  const { editorState, onEditorStateChange, editorData } = useEditorData();
  const wysiwigLength = editorState
    .getCurrentContent()
    .getPlainText('')
    .trim().length;

  const handleEditorChange = newEditorState => {
    onEditorStateChange(newEditorState);
  };
  const contentGeneralData = { editorData, dispatch, setNextStep };

  return {
    formFields,
    wysiwigLength,
    handleEditorChange,
    editorData,
    editorState,
    dispatch,
    setNextStep,
    contentGeneralData
  };
};
