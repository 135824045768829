import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import GoogleFontLoader from 'react-google-font-loader';

import ProgramsApi from 'api/ProgramsApi';
import WallRightBlock from 'components/molecules/wall/blocks/WallRightBlock';
import WallLeftBlock from 'components/molecules/wall/blocks/WallLeftBlock';
import LeftSideLayout from 'components/organisms/layouts/LeftSideLayout';
import useWallLayoutData from 'hooks/wall/useWallLayoutData';
import WallCover from './WallCover';
import { WALL_TYPE } from 'constants/general';
import { REDIRECT_MAPPING, ROOT, WALL } from 'constants/routes';
import { redirectBasedOnCurrentStep } from 'services/WallServices';
import { useWallSelection } from 'hooks/wall/useWallSelection';
import { setProgramDetails } from 'store/actions/wallActions';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';

import style from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';

const programsApi = new ProgramsApi();
/**
 * Organism component used to render wall dashboard layout
 *
 * @constructor
 */
const WallDashBoardLayout = ({ children, hasRightWidgets = true }) => {
  const { history, userDetails, currentStep } = useWallLayoutData();
  const { wallSingleWidget, wallCenterBlock } = style;

  const { selectedProgramId, programDetails } = useWallSelection();
  const { font } = useSelectedProgramDesign();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedProgramId || programDetails[selectedProgramId]) {
      return;
    }
    programsApi
      .getProgramDetails(selectedProgramId)
      .then(data => dispatch(setProgramDetails(selectedProgramId, { ...data, didLoad: true })))
      .catch(() => dispatch(setProgramDetails(selectedProgramId, { didLoad: true })));
  }, [selectedProgramId]);

  if (history.location.pathname.includes(WALL) && !userDetails) {
    return <Redirect to={ROOT} />;
  }

  redirectBasedOnCurrentStep(currentStep, history, [REDIRECT_MAPPING.WALL_ROUTE_STEP, REDIRECT_MAPPING.NOT_PAID_STEP]);

  return (
    <LeftSideLayout theme={WALL_TYPE} hasUserIcon>
      {font && <GoogleFontLoader fonts={[{ font }]} />}
      <div className={style.wallBaseStructure} style={{ fontFamily: font }}>
        <WallCover />
        <WallLeftBlock />
        <div className={`${wallCenterBlock} ${!hasRightWidgets ? wallSingleWidget : ''} ${coreStyle.mt1}`}>
          {children}
        </div>
        {hasRightWidgets && <WallRightBlock />}
      </div>
    </LeftSideLayout>
  );
};

export default WallDashBoardLayout;
