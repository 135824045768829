import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useContext, useEffect } from 'react';

import { IStore } from 'interfaces/store/IStore';
import { AvatarContext } from 'components/pages/PersonalInformationPage';

/**
 * Hook used to expose all avatar data
 *
 * @param setErrors
 * @param setImageError
 */
export const useAvatarData = (setErrors, setImageError) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const avatarContext = useContext(AvatarContext);
  const imageModal = useSelector(state => (state as IStore).modalReducer.imageUploadModal);
  const {
    cropped: { croppedAvatar }
  } = useContext(AvatarContext);

  useEffect(() => {
    setErrors({ requiredImage: '' });
    setImageError({ requiredImage: '' });
  }, [croppedAvatar]);

  return { formatMessage, dispatch, avatarContext, imageModal, croppedAvatar };
};
